<template>
    <div class="row">
        <!-- Family -->
        <div class="col-12" :class="{'disable-scroll' : awards.length <= 2 }">
            <div class="radiogroupdiv" v-if="awards.length > 0">
                <!-- Award Family List -->
                <div class="form-check border-bottom" v-for="(award, awardIndex) in awards" :key="awardIndex">
                    <input  class="form-check-input" 
                      type="radio" 
                      name="exampleRadios" 
                      :id="`exampleRadios1${awardIndex}`" 
                      :value="award.id"
                      :checked="(selected_award == award.id) ? true : false"
                      @change="sendUpdateEmit(award)"
                      >
                    <label class="form-check-label" :for="`exampleRadios1${awardIndex}`">
                      {{award.Translations !== undefined ? award.Translations.en.name : award}}
                    </label>
                    <div class="award-img-containerr" v-html="getImageUrl(award)" v-if="!award.showDefaultImage">
                    </div>
                    <div class="award-img-containerr" v-else>
                      <img src="@/assets/images/icon1.png" class="award-imgg"/>
                    </div>
                    <award-accordion>
                      <template v-slot:content>
                        <p class="award-description">{{award.Translations.en.description ? award.Translations.en.description : "No Description Available"}} </p>
                      </template>
                    </award-accordion>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import eventEmit from '@/mixin/index.js'

import AwardAccordion from "@/components/AwardAccordion";

export default {
    data() {
      return {
        assetUrl: this.$store.getters.domain,
        certificateImage: this.$store.getters.domain,
        showDefaultImage: false
      };
    },
    mixins: [eventEmit],
    props:{
        awards:{
            type: Array
        },
        selected_award:{
            default: ''
        }
    },
    components: {
        AwardAccordion
    },
    mounted () {
      this.sendResizeContent(false, true)
    },
    methods:{
      getImageUrl (award) {
        if (award.widgetType === 'icon') {
          if (award.iconClass !== '') return this.getIcon(award)
          if (award.image !== '') return this.getImage(award)
          if (award.Certificate) return this.getCertificateThumnail(award)
        } else if (award.widgetType === 'image') {
          if (award.image !== '') return this.getImage(award)
          if (award.iconClass !== '') return this.getIcon(award)
          if (award.Certificate) return this.getCertificateThumnail(award)
          } else {
          if (award.Certificate) return this.getCertificateThumnail(award)
          if (award.image !== '') return this.getImage(award)
          if (award.iconClass !== '') return this.getIcon(award)
        }
        award.showDefaultImage = true
      },
      getIcon (award) {
        return `<em class="fa-3x fa-timg fa-image fa-opacity-20 svg-icon ${award.iconClass}" style="color:${award.iconColor}; font-size: 55px;"></em>`
      },
      getImage (award) {
        return `<img class="award-imgg" src="${this.assetUrl + award.image}"/>`
      },
      getCertificateThumnail (award) {
        return `<img class="award-imgg" src="${this.certificateImage + this.getAwardTemplateThumbnailUrl(award.Certificate.Template)}"/>`
      },
        /**
         * Emit For Change
         */
        sendUpdateEmit(award){
          this.$emit('change-award', {award: award})
        },
      getAwardTemplateThumbnailUrl: function(template) {
        let url = ''
        let defaultLang = 0
        if (template.Translations && template.Translations.length > 0) {
          defaultLang = template.Translations[0]
        }
        if (!template.id) {
          url = '/award/cert/thumb/id/' + template.templateId + '/subprogramId/' + template.templateSubprogramId +
            '/isPremade/' + (template.templateIsPremade || 0) + '/defaultLang/' + (defaultLang || 0) +
            '/lang/' + `${this.$store.getters.language}` + '/filename/' + (template.templateFileName || 0)
        } else {
          url = '/award/cert/thumb/id/' + template.id + '/subprogramId/' + template.subprogramId +
            '/isPremade/' + (template.isPremade || 0) + '/defaultLang/' + (defaultLang || 0) +
            '/lang/' + `${this.$store.getters.language}` + '/filename/' + (template.filename || 0)
        }
        return url
      }
    }
}
</script>

<style scoped>
</style>