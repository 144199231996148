<template>
  <div>
    <video id="myVideo" class="video-js w-100" playsinline></video>
  </div>
</template>
<script>
/* eslint-disable */
import 'video.js/dist/video-js.css';
import 'videojs-record/dist/css/videojs.record.css';
import videojs from 'video.js';
import 'webrtc-adapter';
import 'recordrtc';
import 'videojs-record/dist/videojs.record.js';
import 'videojs-record/dist/plugins/videojs.record.ts-ebml.js';
export default {
  data() {
    return {
      isRecordingFinished: false,
      player: '',
      options: {
        controls: true,
        autoplay: false,
        fluid: false,
        loop: false,
        width: 320,
        height: 240,
        bigPlayButton: false,
        controlBar: {
          volumePanel: false
        },
        plugins: {
          record: {
            audio: true,
            video: true,
            maxLength: 600,
            debug: true,
            // convertEngine: 'ts-ebml',
            maxFileSize: 1000000 * (process.env.VUE_APP_ALLOWED_VIDEO_UPLOAD_SIZE || 15), // 15 mb,
            timeSlice: 200
          }
        }
      }
    };
  },
  mounted() {
    /* eslint-disable no-console */
    setTimeout(() => {
      console.log(document.getElementById("#myVideo"))
    }, 1000)
    this.player = videojs('#myVideo', this.options, () => {});
    if (this.player) {
      this.player.record().getDevice()
    }

    // device is ready
    this.player.on('deviceReady', () => {
      this.player.record().start()
    });

    // user clicked the record button and started recording
    this.player.on('startRecord', () => {
      this.isRecordingFinished = false
      this.$emit('recordingStarted')
      console.log('started recording!');
    });

    // user completed recording and stream is available
    this.player.on('finishRecord', () => {
      // the blob object contains the recorded data that
      // can be downloaded by the user, stored on server etc.
      this.isRecordingFinished = true
      // this.player.record().convert()
      this.$emit('recordedVideoFile', this.player.recordedData)
    });

    // error handling
    this.player.on('error', (element, error) => {
      console.log(error)
    });

    this.player.on('deviceError', () => {
      console.log('device error:', this.player.deviceErrorCode)
    });
    // this.player.on('finishConvert', () => {
    //   this.$emit('recordedVideoFile', this.player.convertedData)
    // });
  },
  beforeDestroy() {
    if (this.player) {
      this.player.record().stopDevice()
      this.player.record().destroy();
      this.player.dispose();
    }
  }
};
</script>
