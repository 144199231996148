<template>
  <div class="row">
        <!-- Questions for award selection -->
        <div class="col-12 pt-3" v-for="(data, index) in awardValues" :key="index">
            <div class="radiogroupdiv">
                <p class="h5">
                  {{data.Question.Translations.en.question}}
                </p>
                <div class="form-check border-bottom" v-for="(award, awardIndex) in data.Answers" :key="awardIndex">
                    <input  class="form-check-input" 
                            type="radio" 
                            :name="`exampleRadios${index}`" 
                            :id="`exampleRadios1${awardIndex}${index}`" 
                            :value="award.answerId"
                            :checked="checkForAwardDetermination(award, data)"
                            @change="sendUpdateEmit(award, data)"
                            >
                    <label class="form-check-label" :for="`exampleRadios1${awardIndex}${index}`">
                    {{award.Answer.Translations !== undefined ? award.Answer.Translations.en.answer : award}}
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
  data () {
    return {
      selectedAward: {},
      awardValues: []
    }
  },
  mounted () {
    this.awardValues = this.$store.getters.awardDeterminationQuestions
  },
  computed: {
    validateAward () {
      return this.awardValues.filter(val => {
        return val.selectedAnswer !== null
      })
    }
  },
  methods: {
    checkForAwardDetermination (answers, question) {
      let dqa = this.$store.getters.dqa
      return dqa[question.id] === answers.id
    },
    sendUpdateEmit (answer, award) {
      let dqa = {...this.$store.getters.dqa}
      dqa[award.id] = answer.id
      this.$store.dispatch('updateDQA', dqa)
      award.selectedAnswer = answer.id
      this.$store.dispatch('setAwardDeterminationQuestions', this.awardValues)
      if (this.validateAward.length === this.$store.getters.awardDeterminationQuestions.length) {
        this.checkForTheAnswer()
      }
    },
    checkForTheAnswer (data, award) {
      this.$emit('enableNextButton')
    }
  },
  props:['questionsForAwardValue']
}
</script>