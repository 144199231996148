<template>
  <div class="stepper_wrapper mx-auto">
      <div class="row justify-content-center" v-if="tab !== 0">
        <div class="col-12 p-0">
          <section class="form-section form_body" style="overflow: auto; height: 100vh;">
            <div class="">
              <div class="p-3" v-if="tab <= 4 && !showResult">
                <h4 class="default-text">{{stepTitles}}</h4>
              </div>
              <div class="award-selection-options" v-if="!showResult">
                      <app-screen-two
                        v-if="(tab == 1)"
                        :members="familyMembers"
                        :selected_family_member="selectedAwardFamily"
                        @change-family="changeFamilyMember"
                        ></app-screen-two>
                      <app-screen-one
                        v-if="(tab == 2)"
                        :members="members"
                        :noEmployeesFound="noEmployeesFound"
                        :selected_members="selectedMembers"
                        @update-member="updateMember"
                        :membersSearchDone="membersSearchDone"
                        :searchMemberText.sync="searchMemberText"
                        ></app-screen-one>
                        <app-screen-three
                        v-if="tab == 3 && !isQuestionTypeAward"
                        :awards="awards"
                        :selected_award="selectedAward"
                        @change-award="changeAward"
                        ></app-screen-three>
                        <app-questions-screen
                        v-if="tab == 3 && isQuestionTypeAward && !isQuestionForAwardValue"
                        @enableNextButton="isButtonDisabled = false"
                        >
                        </app-questions-screen>
                        <app-questions-award-value
                          v-if="tab == 3 && isQuestionTypeAward && isQuestionForAwardValue"
                        :questionsForAwardValue="questionsForAwardValue"
                        @enableNextButton="isButtonDisabled = false">
                        </app-questions-award-value>
                        <app-screen-four
                          v-show="tab == 4"
                          v-if="selectedAwardDetails"
                          :enableDatePickerToggle="enableDate"
                          :formData="awardDetails"
                          :showLoader.sync="showLoader"
                          @validForm="submitAwardDetails"
                          :selectedAwardDetails="selectedAwardDetails"
                          :uploadedVideoDetails="uploadedVideoDetails"
                          @videoUploaded="videoUploadHandler"
                          @uploadingVideo="isVideoUploading = true"
                          @videoApiFailed="tab = 500"
                          :tabId="tab"
                          :checksForAwardDescriptionAndMessage="checksForAwardDescriptionAndMessage"
                          @enableDatepicker="enableDatepickerHandler"
                          ></app-screen-four>
                        <app-unauthorized-error
                        :key="tab"
                        v-if="tab == 401"
                        ></app-unauthorized-error>
                        <app-internal-server-error
                        :key="tab"
                        v-if="tab == 500"
                        ></app-internal-server-error>
                <!-- Next & Previous Button -->
                </div>
                <footer>
                <div class="row bottom-navigation footer-content" v-if="!showResult">
                  <div class="col-12" v-if="(tab == 1) || (tab == 2) || (tab == 3) || (tab == 4) || (tab == 500)">
                    <!-- Back Link -->
                    <a class="text-primary text-back" v-if="showBackLink" @click.prevent="moveNext(tab - 1, true)"><i class="fa fa-chevron-left"></i> {{backToPreviousText}}
                    </a>
                    <div v-else style="height:38px">

                    </div>
                    <!-- Next Button -->
                    <button :disabled="isButtonDisabled && tab !== 500" type="button" class="btn btn-primary d-block w-100 text-upper nextbtn" @click.prevent="moveNext(tab + 1)">
                      {{bottonNavigationButton}}
                    </button>
                    <!-- Active Link -->
                    <ol class="carousel-indicators text-primary" v-if="tab !== 500">
                      <li
                        v-if="!isAwardFamilyPreSelected" @click="indicatorClickHandler(1)" :class="(tab == 1) ? 'bg-primary' : 'border border-primary'" id="screen1"></li>
                      <li @click="indicatorClickHandler(2)" :class="(tab == 2) ? 'bg-primary' : 'border border-primary'" id="screen2"></li>
                      <li v-if="!isAwardDetailsPreSelected" @click="indicatorClickHandler(3)" :class="(tab == 3) ? 'bg-primary' : 'border border-primary'" id="screen3"></li>
                      <li @click="indicatorClickHandler(isAwardDetailsPreSelected ? 3 : 4)" :class="(tab == 4) ? 'bg-primary' : 'border border-primary'" id="screen4"></li>
                    </ol>
                  </div>
                </div>
                </footer>
            </div>
            <app-result @submitNewAward="restartNomination" v-if="showResult"/>
          </section>
        </div>
      </div>
    <app-loader v-if="showLoader"></app-loader>
  </div>
</template>
<script>
import ScreenOne from '@/components/ScreenOne';
import ScreenTwo from '@/components/ScreenTwo';
import ScreenThree from '@/components/ScreenThree';
import ScreenFour from '@/components/ScreenFour';
import loader from '@/components/loader';
import finalScreen from '@/components/finalScreen';
import NominationApi from '@/service/api'
import unauthorizedError from '@/components/401'
import internalServerError from '@/components/500';
import questions from '@/components/questions';
import questionsForAwardValueComponent from '@/components/questionsForAwardValue';
import sendResize from '@/mixin/index.js';
import setPreselectedAward from '@/mixin/preSelectedData.js'
import { displayDescriptionAndMessage, allowToDelayIssuanceDate } from '@/utils'
import errorHandling from '@/mixin/errorHandling.js'
import getTranslation from '@/mixin/translations.js'
export default {
  mixins: [
    sendResize,
    setPreselectedAward,
    errorHandling,
    getTranslation
  ],
	data() {
		return {
			tab: 0,
			members:[],
			selectedMembers:[],
			familyMembers:[],
			selectedAwardFamily: '',
			awards:[],
      enableDate: false,
			selectedAward: '',
			awardDetails:{
        award_points: '',
        fileName: '',
        description: '',
        message: '',
        filePath: '',
        awardForDate: ''
      },
      showLoader: false,
      searchMemberText: '',
      showResult: false,
      validateAwardDetails: false,
      selectedAwardDetails: null,
      selectedAwardFamilyDetails: null,
      uploadedVideoDetails: null,
      toastPosition: "top-right",
      isQuestionTypeAward: false,
      isQuestionForAwardValue: false,
      isButtonDisabled: true,
      questionsForAwardValue: [],
      checksForAwardDescriptionAndMessage: {
        displayDescription: false,
        descriptionIsMandatory: false,
        displayMessage: false,
        messageIsMandatory: false,
        allowAttachments: false,
        enableDate: true
      },
      noEmployeesFound: false,
      scrollHeightCheckInterval: null,
      documentScrollHeight: null,
      membersSearchDone: false,
      isVideoUploading: false,
      isAwardFamilyChanged: false,
      previouslySelectedAwardFamily: null,
      appRestarted: false,
      continueSessionInterval: null,
      ignoretoastr: false
		}
	},
  watch: {
    isAwardFamilyChanged: {
      handler () {
        if (this.isAwardFamilyChanged) {
          this.$root.$emit('deleteUploadedVideo')
          this.resetNominationData(true)
          this.selectedAwardDetails = null
          this.showLoader = false
        }
      }
    },
    isVideoUploading: {
      handler () {
        this.checkForButtonForAwardDetails()
      }
    },
    selectedAwardDetails: {
      handler () {
        if (this.selectedAwardDetails) {
          this.checksForAwardDescriptionAndMessage = {...this.checksForAwardDescriptionAndMessage, ...displayDescriptionAndMessage(this.selectedAwardDetails)}
        }
      },
      deep: true
    },
    awardDetails: {
      handler () {
        if (this.tab === 4) {
          this.checkForButtonForAwardDetails()
        }
      },
      deep: true
    },
    enableDate: {
      handler () {
        this.checkForButtonForAwardDetails()
      }
    },
    selectedMembers: {
      handler () {
        if (this.selectedMembers && this.selectedMembers.length > 0 && this.checkNominationMemberLimit()) {
          this.isButtonDisabled = false
        }
      },
      deep: true
    },
    selectedAwardFamily: {
      handler () {
        if (this.selectedAwardFamily !== '') {
          this.isButtonDisabled = false
        } else {
          this.isButtonDisabled = true
        }
      },
      deep: true
    },
    selectedAward: {
      handler () {
        if (this.selectedAward) {
          this.isButtonDisabled = false
        } else {
          this.isButtonDisabled = true
        }
      },
      deep: true
    },
    selectedAwardFamilyDetails: {
      handler () {
        let enableDate = allowToDelayIssuanceDate(this.selectedAwardFamilyDetails.member)
        this.checksForAwardDescriptionAndMessage.enableDate = enableDate
        this.checksForAwardDescriptionAndMessage.allowAttachments = this.selectedAwardFamilyDetails.member.allowAttachments > 0
      },
      deep: true
    },
    searchMemberText: {
      handler () {
        if (this.searchMemberText !== "") {
          this.getMembersList()
        }
      }
    },
    isQuestionTypeAward: {
      handler () {
        this.isButtonDisabled = true
      }
    },
    tab: {
      handler () {
        if (this.tab === 2) {
          this.noEmployeesFound = false
          if (this.selectedMembers.length === 0) {
            this.isButtonDisabled = true
          }
        }
        this.searchMemberText = ""
        if (this.tab === 4) this.checkForButtonForAwardDetails()
        if (this.tab === 500) this.isButtonDisabled = false
      }
    }
  },
	//Components
	components: {
		'app-screen-one'	: ScreenOne,
		'app-screen-two'	: ScreenTwo,
		'app-screen-three'	: ScreenThree,
    'app-screen-four'	: ScreenFour,
    'app-loader': loader,
    'app-result': finalScreen,
    'app-unauthorized-error': unauthorizedError,
    'app-internal-server-error': internalServerError,
    'app-questions-screen': questions,
    'app-questions-award-value': questionsForAwardValueComponent
	},
  beforeMount () {
    let data = this.$store.getters.awardSelectedData
    if (!data && data !== null) {
      if (data.selectedMembers) this.selectedMembers = data.selectedMembers
      if (data.selectedAward) this.selectedAward = data.selectedAward
      if (data.awardFamilyId) this.selectedAwardFamily = data.awardFamilyId
    }
  },
  created () {
    this.scrollHeightCheckInterval = setInterval(() => {
      if (this.documentScrollHeight !== document.body.scrollHeight) {
        this.documentScrollHeight = document.body.scrollHeight
        this.sendResizeContent(false, false)
      }
    }, 500)
    this.checkPreselectedData()
  },
  mounted () {
    this.requestInitialPostMessage()
    let preSelectedData = this.$store.getters.preSelectedAwardIds
    if (!this.isAwardFamilyPreSelected && !preSelectedData) {
      this.getAwardFamily()
    }
    // this is done for check if it is mobile view or not. if mobile view than position of toast becomes center
    window.onresize = () => {
      this.toastPosition = window.innerWidth < 1064 ? "top"  : "top-right"
    }
    const continueSessionFreq = this.$store.getters.continueSessionIntervalInSecs
    if (continueSessionFreq) {
      this.continueSessionInterval = setInterval(() => {
        this.continueSession()
      }, continueSessionFreq * 1000)
    }
  },
  computed: {
    doesDescriptionOfAchievementExceedsCharLimit () {
        if (this.selectedAwardDetails) {
          if (this.selectedAwardDetails.descriptionAchievementLimit && this.awardDetails.description) {
            return this.awardDetails.description.length > this.selectedAwardDetails.descriptionAchievementLimit
          }
        }
        return false
      },
      doesMessageForNomineeExceedsCharLimit () {
        if (this.selectedAwardDetails) {
          if (this.selectedAwardDetails.messageRecipientLimit && this.awardDetails.message) {
            return this.awardDetails.message.length > this.selectedAwardDetails.messageRecipientLimit
          }
        }
        return false
      },
      descriptionOfAchievmentErrorMessage () {
        if (this.doesDescriptionOfAchievementExceedsCharLimit) {
          return this.$store.getters.translations.descriptionToManyCharacters || this.$t('message.descriptionToManyCharacters')
        }
        return $tc("message.awardDetailsValidation", 1)
      },
      messageForNomineeErrorMessage () {
        if (this.doesMessageForNomineeExceedsCharLimit) {
          return this.$store.getters.translations.messageToManyCharacters || this.$t('message.messageToManyCharacters')
        }
        return $tc("message.awardDetailsValidation", 2)
      },
    backToPreviousText () {
      return this.getTranslations("backToPrevious") || this.$t("message.backButton")
    },
    stepTitles () {
      if (this.tab === 1) {
        return this.getTranslations("selectAwardFamily") || this.$tc("message.step1Title")
      } else if (this.tab === 2) {
        return this.getTranslations("selectNominees") || this.$tc("message.step2Title")
      } else if (this.tab === 3) {
        return this.getTranslations("selectAward") || this.$tc("message.step3Title")
      } else if (this.tab === 4) {
        return this.getTranslations("enterAwardDetails") || this.$t("message.awardDetailsHeader")
      }
      return ''
    },
    showBackLink () {
      return ((this.tab > 1 && !this.isAwardFamilyPreSelected) || (this.isAwardFamilyPreSelected && this.tab > 2)) && this.tab !== 500
    },
    getMemberPins () {
      if (!this.selectedMembers) return []
      return this.selectedMembers.map(val => {
          return val.pin
        })
    },
    getCompanyName () {
      let data = this.$store.getters.companyName
      return data === null ? '' : data
    },
    bottonNavigationButton () {
      if (this.tab === 500) {
        return this.getTranslations("restart") || this.$tc("message.restart")
      } else if (this.tab === 4) {
        return this.getTranslations("submit") || this.$tc("message.submit")
      }
      return this.getTranslations("next") || this.$tc("message.next")
    },
    validateAwardValueAnswer () {
      let filteredData = this.questionsForAwardValue.filter(val => {
        return val.selectedAnswer !== null
      })
      return filteredData.length !== this.questionsForAwardValue.length
    }
  },
	//   Methods
	methods: {
    enableDatepickerHandler (data) {
      this.enableDate = data
    },
    async continueSession () {
      this.showLoader = true
      const cookieExpiresIn = this.$store.getters.continueSessionIntervalInSecs
      NominationApi.continueSession(cookieExpiresIn).then(() => {
        this.showLoader = false
      }).catch(error => {
        this.showLoader = false
        if(error.response?.status == 401){
          this.tab = 401;
        } else if (error.response?.status === 409) {
          this.openToastNotificationForMessage(error.response.data.error.message, 'error')
        } else{
          this.tab = 500;
        }
      })
    },
    // will get uploaded video details to keep it in state
    videoUploadHandler (data) {
      this.isVideoUploading = false
      this.uploadedVideoDetails = data
      this.awardDetails.fileName = data.Key
    },
    // restart nomination
    restartNomination () {
      if (this.isAwardFamilyPreSelected) {
        this.tab = 2
      } else {
        this.tab = 1
      }
      this.showResult = false
      this.resetNominationData()
    },
    // reset Nomination data to intial values
    async resetNominationData (keepAwardFamily) {
      this.isButtonDisabled = true
      this.selectedMembers = []
      this.uploadedVideoDetails = null
      this.selectedAward = ''
      this.selectedAwardDetails = null
      this.awardDetails = {
        award_points: '',
        fileName: '',
        description: '',
        message: ''
      }
      this.awards = []
      this.members = []
      this.$store.dispatch('updateDQA', {})
      this.$store.dispatch('updateVQA', {})
      this.$store.dispatch('resetAwardData')
      this.isQuestionForAwardValue = false
      this.isQuestionTypeAward = false
      if (!keepAwardFamily && !this.isAwardFamilyPreSelected) {
        this.$store.dispatch('updateFamilyAward', null)
        this.selectedAwardFamily = ''
        if (this.isAwardFamilyPreSelected) {
          if (this.$store.getters.preSelectedAwardFamily) {
            this.selectedAwardFamilyDetails = {
              member: this.$store.getters.preSelectedAwardFamily
            }
          } else {
            this.checkPreselectedData()
          }
        }
      }
      if (this.isAwardDetailsPreSelected ) {
        const awardDetails = this.$store.getters.preSelectedAward
        if (awardDetails) {
          this.selectedAwardDetails = awardDetails
          this.selectedAward = awardDetails.id
        } else {
          this.checkPreselectedData()
        }
      } else {
        this.$store.dispatch('selectedAwards', null)
      }
    },
    // Getting member list
    getMembersList () {
      // Calling getfamilyaward api with param searchText
      this.membersSearchDone = false
      NominationApi.advancedFamilyMembers(this.searchMemberText, this.selectedAwardFamily)
      .then(res => {
        this.showLoader = false
        const members = []
        if (!res.data || res.data.length === 0) {
          this.noEmployeesFound = true
          this.members = []
          this.membersSearchDone = true
          return
        }
        this.noEmployeesFound = false
        for (let val of res.data) {
            if (val && val.fName && val.lName)  {
              val.fullName = `${val.fName} ${val.lName}`
              if (this.selectedMembers) {
                let index = this.selectedMembers.findIndex(mem => {
                  return val.pin === mem.pin
                })
                if (index === -1) {
                  members.push(val)
                }
              } else {
                members.push(val)
              }
            }
          }
        this.membersSearchDone = true
        this.members = members
      })
      .catch(error => {
        this.showLoader = false
        if(error.response?.status == 401){
          this.tab = 401;
        } else if (error.response?.status === 409) {
          this.openToastNotificationForMessage(error.response.data.error.message, 'error')
        } else{
          this.tab = 500;
        }
      })
    },
    // Getting awardFamily
    getAwardFamily () {
      this.showLoader = true
      // Calling get award family with param awardFamilyId
      NominationApi.getAwardFamily('')
      .then(response => {
        this.familyMembers = response.data
        this.showLoader = false
        this.tab = 1
      })
      .catch(error => {
        this.showLoader = false
        if(error.response.status == 401){
          this.tab = 401;
        } else {
          this.tab = 500;
          if (error.response.status === 409) this.openToastNotificationForMessage(error.response.data.error.message, 'error')
        }
      })
    },
    async canNominateWithSelectedFamily () {
      const [response, error] = await NominationApi.canNominate(this.selectedAwardFamily)
      if (error) {
        if(error.response.status == 401){
          this.tab = 401;
        } else if (error.response.status === 409) {
          this.openToastNotificationForMessage(error.response.data.error.message, 'error')
        } else{
          this.tab = 500;
        }
        return false
      }
      if (!response.canNominate) {
        this.openToastNotificationForMessage(this.$t('message.cannotNominate'), 'error')
        return false
      }
      return true
    },
    // Getting Awards
    async getAwards () {
      this.showLoader = true
      let isFailed = false
      // Calling api for awards with param awardId
      let nominess = this.selectedMembers.map(val => {
        return val.pin
      })
      NominationApi.getAwards('', nominess, '', this.selectedAwardFamily)
      .then(response => {
        this.awards = response.data.map(val => {
          val.showDefaultImage = false
          return val
        })
        this.showLoader = false
        if (response.data?.length === 0) {
          isFailed = true
          this.openToastNotificationForMessage(this.$t('message.noCommonAwardAvailable'), 'error')
          return
        }
        if (this.isAwardDetailsPreSelected) {
          let awardsList = response.data.filter(val => {
            val.showDefaultImage = false
            return val.id === this.selectedAward
          })
          if (awardsList.length === 0) {
            isFailed = true
            this.openToastNotificationForMessage(this.$t('message.noCommonAwardAvailable'), 'error')
            this.tab = 2
            return
          } else {
            this.checkBudgetsCap(4)
          }
        } else {
          this.tab = 3
        }
        this.$store.dispatch('updateDQA', {})
        this.$store.dispatch('updateVQA', {})
      })
      .catch(error => {
        this.showLoader = false
        if(error.response.status == 401){
          this.tab = 401;
        } else if (error.response.status === 409) {
          // Go to member selection
          this.tab = 2
          this.openToastNotificationForMessage(error.response.data.error.message, 'error')
        } else if(error.response.status == 412){
          this.openToast(4, 'error');
        }else{
          this.tab = 500;
        }
      })
      return isFailed
    },
    getQuestionsForAwardType () {
      NominationApi.findWithAnswersByAwardFamily(this.selectedAwardFamily)
      .then(response => {
        this.tab = 3
        let dqa = this.$store.getters.dqa
        let dummydqa = {}
        if (dqa && response.data) {
          Object.entries(dqa).forEach(([key, value]) => {
            let index = response.data.findIndex(val => {
              return String(val.id) === String(key)
            })
            if (index > -1) {
              dummydqa[key] = value
            }
          })
          this.$store.dispatch('updateDQA', dummydqa)
        }
        this.isQuestionTypeAward = true
        this.awards = response.data.map(val => {
          val.selectedAnswer = dqa[val.id] ? dqa[val.id] : null
          return val
        })
        this.$store.dispatch('setAwardDeterminationQuestions', this.awards)
      })
      .catch(error => {
        this.showLoader = false
        if(error.response.status == 401){
          this.tab = 401;
        } else if(error.response.status == 412){
          this.openToastNotificationForMessage(this.$t('message.seemsLikeYouCannotNominate'), 'error')
        } else if (error.response.status === 409) {
          // Go to member selection
          this.tab = 2
          this.openToastNotificationForMessage(error.response.data.error.message, 'error')
        } else{
          this.tab = 500;
        }
      })
    },
    checkIfAwardFamilyChanged () {
      if (this.previouslySelectedAwardFamily) {
        if (this.previouslySelectedAwardFamily.member.id !== this.selectedAwardFamilyDetails.member.id) {
          this.isAwardFamilyChanged = true
          this.previouslySelectedAwardFamily = this.selectedAwardFamilyDetails
        } else {
          this.isAwardFamilyChanged = false
        }
      }
    },
		/**
		 * moveNext
		 * @param Tab Id
		 */
		moveNext (tabId, isBack) {
      if (isBack) {
        this.previouslySelectedAwardFamily = {
          member: {...this.selectedAwardFamilyDetails.member}
        }
        this.isAwardFamilyChanged = false
      }
      if (!isBack && tabId === 2) {
        this.checkIfAwardFamilyChanged()
      }
      if (tabId === 2 && this.isQuestionForAwardValue) {
        this.isQuestionForAwardValue = false
        tabId = 3
      }
      if (tabId < 1) return
      if (tabId === 1) {
        this.tab = tabId
        this.isButtonDisabled = this.selectedAwardFamily === ''
        this.getAwardFamily()
        return
      }
      if (tabId === 501) {
        this.restartNomination()
        return
      }
      this.validations(tabId, isBack)
    },
    // validations
    async validations (tabId, isBack) {
      if (tabId === 3) {
        if (!isBack) {
          const areValidMemberSelected = await this.checkNotNominatableFromFamilyHandler()
          if (!areValidMemberSelected) {
            this.tab = 2
            return
          }
          const memberOutOfRange = this.checkNominationMemberLimit()
          if (!memberOutOfRange) {
            //this.isButtonDisabled = true
            return
          }

          if (this.selectedMembers.length > 0) {
            this.checkForNomineesFamilyCap(tabId)
          }else{
            this.openToast(0, 'warning');
          }
        } else {
          this.tab = this.isAwardDetailsPreSelected ? tabId - 1 : tabId
          this.isButtonDisabled = false
        }
      } else if (tabId === 2) {
        if (this.selectedMembers.length === 0) {
          this.isButtonDisabled = true
        } else {
          this.isButtonDisabled = false
        }
        const canNominate = await this.canNominateWithSelectedFamily()

        if (!canNominate) {
          this.isButtonDisabled = true
          return
        }

        if (!this.checkNominationMemberLimit()) {
          //this.isButtonDisabled = true
        }

        if (this.selectedAwardFamily > 0 && this.selectedAward && this.selectedAwardDetails?.rewardStyle !== 'plateau') {
          this.checkNominatorFamilyCap()
        }
        this.tab = tabId
      } else if (tabId === 4) {
        if (this.isQuestionTypeAward && !this.isQuestionForAwardValue) {
          this.checkForTheAnswerForAwardDetermination()
          return
        }
        if (this.isQuestionTypeAward && this.isQuestionForAwardValue) {
          this.checkForAnswerForAwardValue()
          return
        }
        if (this.selectedAward > 0) {
          this.ignoretoastr = true
          this.checkBudgetsCap(tabId)
        }else{
          this.openToast(2, 'warning');
        }
      } else {
        this.$root.$emit('validateFormData', true)
      }
    },
    checkNominationMemberLimit () {
      if (!this.selectedAwardFamilyDetails) return false
      if (this.selectedAwardFamilyDetails.member.nomLimit) {
        const {nomLimitMax, nomLimitMin} = this.selectedAwardFamilyDetails.member
        const count = this.getMemberPins.length
        if (nomLimitMax > 0 && nomLimitMin > 0 && nomLimitMax === nomLimitMin) {
          if (count !== nomLimitMax) {
            this.openToastNotificationForMessage(this.$t('message.mustSelectedError', {min: nomLimitMax, nominee: `nominee${nomLimitMin > 1 ? 's' : ''}`}), 'error')
            return false
          }
        } else {
          if (nomLimitMin > 0 && nomLimitMax > 0) {
            if (count < nomLimitMin || count > nomLimitMax) {
              if(count !== 0) {
                  this.openToastNotificationForMessage(this.$t('message.selectBetweenError', {min: nomLimitMin, max: nomLimitMax}), 'error')
                }
              return false
            }
          } else if (nomLimitMax > 0 && count > nomLimitMax) {
            this.openToastNotificationForMessage(this.$t('message.maximumMemberError', {max: nomLimitMax, nominee: `nominee${nomLimitMax > 1 ? 's' : ''}`}), 'error')
            return false
          } else if (nomLimitMin > 0 && count < nomLimitMin) {
            this.openToastNotificationForMessage(this.$t('message.minimumError', {min: nomLimitMin, nominee: `nominee${nomLimitMin > 1 ? 's' : ''}`}), 'error')
            return false
          }
        }
      }
      return true
    },
    // column decides what message to show from translater
    openToast (column, type) {
      this.$toast.open({
        message: this.$tc("message.selectionErrorMessage", column),
        type: type,
        position: this.toastPosition
      })
      this.sendResizeContent(false, true)
    },
    openToastNotificationForMessage (message, type) {
      if (!this.ignoretoastr) {
        this.$toast.open({
          message: message ? message : 'Something went wrong',
          type: type,
          position: this.toastPosition
        })
        if (type === 'error') {
          this.sendResizeContent(false, true)
        }
      } else {
        this.ignoretoastr = false
      }
    },
    // submit all awards details
    submitAwardDetails () {
      if (this.doesMessageForNomineeExceedsCharLimit) {
        this.openToastNotificationForMessage(this.messageForNomineeErrorMessage, 'error')
      }

      if (this.doesDescriptionOfAchievementExceedsCharLimit) {
        this.openToastNotificationForMessage(this.descriptionOfAchievmentErrorMessage, 'error')
      }
      if (this.doesDescriptionOfAchievementExceedsCharLimit || this.doesMessageForNomineeExceedsCharLimit) {
        return
      }
      this.showLoader = true
      if (this.selectedAwardDetails?.rewardStyle === 'plateau') {
        this.awardDetails.award_points = Number(this.selectedAwardDetails.creditValue) || 0
      } else {
        if (this.selectedAwardDetails?.monetarySetup === 'fixed') {
          this.awardDetails.award_points = Number(this.selectedAwardDetails.awardPrice.fixed) || 0
        }
        if (this.selectedAwardDetails?.monetarySetup === 'none') {
          this.awardDetails.award_points = 0
        }
      }
      let data = {
        awardId: this.selectedAward,
        awardValue: this.awardDetails.award_points || 0,
        familyId: this.selectedAwardFamily,
        selectedMembers: this.getMemberPins,
        achievementDescription: this.awardDetails.description,
        emailMessage: this.awardDetails.message,
        attachmentFilename: this.awardDetails.fileName,
        awardForDate: !this.enableDate ? '' : this.awardDetails.awardForDate,
        enableDate: false,
        dqa: this.$store.getters.dqa,
        vqa: this.$store.getters.vqa,
        awardedBy: this.$store.getters.awardedBy,
        awardedByFirstName: this.$store.getters.awardedByFirstName,
        awardedByLastName: this.$store.getters.awardedByLastName
      }
      if (this.enableDate) {
        data.enableDate = true
        data.awardForDate = new Date(this.awardDetails.awardForDate).toISOString()
      }
      // calling api
      NominationApi.submitAwardDetails(data)
      .then(response => {
        this.showLoader = false
        let isErrorMessage = this.checkForErrorMessages(response)

        if (response.data && !isErrorMessage) {
          this.showResult = true
          // once submitted reset state
          this.resetNominationData()
        }

      })
      .catch(error => {
        this.showLoader = false
        if(error.response.status == 401){
          this.tab = 401;
        } else if (error.response.status === 400 || error.response.status === 404 || error.response.status === 409) {
          this.openToastNotificationForMessage(error.response.data.error.message, 'error')
        } else{
          this.tab = 500;
        }
      })
    },
		/**
		 * Update Member
		 */
		updateMember(data){
			if(data.type == 'add'){
				//Add New Member
				let foundObj = this.selectedMembers.filter(function(element){
					if(element.pin == data.member.pin) return element;
				});

				if(foundObj.length == 0){
					this.selectedMembers.push(data.member);
          this.$store.dispatch('updateSelectedMembers', this.selectedMembers)
				}
			}else{
				// Remove Member
				this.selectedMembers.splice(data.index, 1);
			}
		},

		/**
		 * Change Family Member
		 */
		async changeFamilyMember(data){
      this.$store.dispatch('updateFamilyAward', data.member.id)
      this.selectedAwardFamily = data.member.id;
      this.selectedAwardFamilyDetails = data
		},

		/**
		 * Change Award
		 */
		changeAward(data){
      this.selectedAward = data.award.id;
      // For getting fixed or ranged values
      this.selectedAwardDetails = data.award
      this.isButtonDisabled = false
		},
    changeAwardFromQuestions (data) {
      this.selectedAward = data.id;
      // For getting fixed or ranged values
      this.selectedAwardDetails = data
    },
    awardValueForQuestionHandler (data) {
      this.awardDetails.award_points = Number(data.value) || 0
    },
    checkForAnswerForAwardValue () {
      this.showLoader = true
      let familyAwardId = this.$store.getters.familyAwardId
      let members = this.$store.getters.pinOfMembers
      let answer = this.$store.getters.vqa
      NominationApi.checkForAwardValueAnswers(familyAwardId, members, this.selectedAward, answer)
      .then(val => {
        this.showLoader = false

        if (!val?.data?.value) {
          this.openToastNotificationForMessage(this.$t('message.awardValueNotDetermine'), 'error')
        }
        let hasError = this.checkForErrorMessages(val)
        if (hasError) return
        this.isQuestionForAwardValue = true
        this.$store.dispatch('updateVQA', answer)
        this.awardDetails.award_points = Number(val.data.value) || 0
        this.checkForNominationPrevention(this.tab + 1)
      })
      .catch(error => {
        this.showLoader = false
        if(error.response.status == 401){
          this.tab = 401;
        } else if (error.response.status === 409) {
          this.openToastNotificationForMessage(error.response.data.error.message, 'error')
        } else{
          this.tab = 500;
        }
      })
    },
    checkForTheAnswerForAwardDetermination () {
      this.showLoader = true
      let familyAwardId = this.$store.getters.familyAwardId
      let members = this.$store.getters.pinOfMembers
      let answer = this.$store.getters.dqa
      NominationApi.checkDeterminationAnswers(familyAwardId, members, answer)
      .then(resp => {
        if (resp.data?.id) {
          this.openToastNotificationForMessage(this.$t('message.awardSelected', {award: resp.data.Translations.en.name}), 'success')
        } else {
          this.openToastNotificationForMessage(this.$t('message.noAwardForAnswer'), 'success')
        }
        this.showLoader = false
        this.$store.dispatch("selectedAwards", resp.data)
        this.$store.dispatch("updateDQA", answer)
        this.changeAwardFromQuestions(resp.data)
        if (resp.data.monetarySetup !== 'fixed' && resp.data.rewardStyle !== 'plateau' && resp.data.monetarySetup !== 'none') {
          this.getQuestionsForAwardValue(resp.data)
          return
        }
        if (resp.data.rewardStyle === 'plateau') {
          this.awardDetails.award_points = resp.data.creditValue
        }
        this.selectedAward = resp.data.id
        this.selectedAwardDetails = resp.data
        this.checkForNominationPrevention(this.tab + 1)
        this.isQuestionForAwardValue = false
        this.$store.dispatch('updateVQA', {})
      })
      .catch(error => {
        this.showLoader = false
        if(error.response.status == 401){
          this.tab = 401;
        }else if(error.response.status == 412){
          this.openToast(4, 'error');

        } else if (error.response.status === 409) {
          this.openToastNotificationForMessage(error.response.data.error.message, 'error')
        } else{
          this.tab = 500;
        }
      })
    },
    getQuestionsForAwardValue (data) {
      this.showLoader = true
      let familyAwardId = this.$store.getters.familyAwardId
      NominationApi.findWithAnswersByAwardSet(familyAwardId, data.id)
      .then(resp => {
        this.showLoader = false
        this.isQuestionForAwardValue = true
        let vqa = this.$store.getters.vqa
        this.questionsForAwardValue = resp.data.map(val => {
          val.selectedAnswer = vqa[val.id] ? vqa[val.id] : null
          return val
        })
        this.$store.dispatch('setAwardValueQuestions', this.questionsForAwardValue)
        this.isButtonDisabled = this.validateAwardValueAnswer
      })
      .catch(error => {
        this.showLoader = false
        if(error.response.status == 401){
          this.tab = 401;
        }else if(error.response.status == 412){
          this.openToast(4, 'error');
        } else if (error.response.status === 409) {
          this.openToastNotificationForMessage(error.response.data.error.message, 'error')
        } else{
          this.tab = 500;
        }
      })
    },
    checkForButtonForAwardDetails () {
      if ((this.isQuestionForAwardValue && !this.awardDetails.award_points) || (this.checksForAwardDescriptionAndMessage.descriptionIsMandatory && !this.awardDetails.description) || (this.checksForAwardDescriptionAndMessage.messageIsMandatory && !this.awardDetails.message) || (this.enableDate && !this.awardDetails.awardForDate) || this.isVideoUploading) {
        this.isButtonDisabled = true
      } else {
        this.isButtonDisabled = false
      }
    },
    // Check for nominator budget cap
    async checkNominatorFamilyCap () {
      NominationApi.checkNominatorFamilyCap(this.selectedAwardFamily)
      .then(response => {
        if (!response.data.result) {
          this.openToastNotificationForMessage(`${this.$t("message.insufficientBalance")}`, 'warning')
          this.openToastNotificationForMessage(`${this.$t("message.availableBalance")} ${response.data.remains || 0} points.`, 'warning')
        } else if (response.data.remains) {
            this.openToastNotificationForMessage(`${this.$t("message.availableBalance")} ${response.data.remains} points.`, 'success')
          }
      })
      .catch(error => {
        this.showLoader = false
        if(error.response.status == 401){
          this.tab = 401;
        }else{
          this.openToastNotificationForMessage(error.response.data.error.message, 'error')
        }
      })
    },
    async checkNotNominatableFromFamilyHandler () {
      this.showLoader = true
      const [response, error] = await NominationApi.checkNotNominatableFromFamily(this.selectedAwardFamily, this.getMemberPins)
      this.showLoader = false
      if (error) {
        if(error.response.status == 401){
          this.tab = 401;
          return
        }else{
          this.openToastNotificationForMessage(error.response.data.error.message, 'error')
        }
      }

      if (response && response.length > 0) {
        this.openToastNotificationForMessage(`${this.$t('message.permissionFailedForMember')}: ${response.join(',')}`)
        return false
      }
      return true
    },
    // Check for nominator budget cap
    checkForNomineesFamilyCap (tabId) {
      let members = this.getMemberPins
      this.showLoader = true
      NominationApi.checkNomineesFamilyCap(this.selectedAwardFamily, members)
      .then(response => {
        this.showLoader = false
        if (response.data && response.data?.length > 0) {
          response.data.forEach(val => {
            this.openToastNotificationForMessage(this.$t('message.checkNomineeFamilyCapError', {
              nomineeName: val.fullName
            }), 'error')
          })
          return
        }
        if (this.isAwardDetailsPreSelected) {
          if (this.selectedAwardFamilyDetails?.member?.type !== 'wizard') {
            this.getAwards()
          } else {
            this.checkBudgetsCap(tabId + 1)
          }
          return
        }
        // checking if family award type is wizard
        if (this.selectedAwardFamilyDetails?.member?.type === 'wizard') {
          this.getQuestionsForAwardType()
        } else {
          this.isQuestionTypeAward = false
          this.isQuestionForAwardValue = false
          this.getAwards()
        }
        this.isButtonDisabled = !this.selectedAward
      })
      .catch(error => {
        this.showLoader = false
        if(error.response.status == 401){
          this.tab = 401;
        }else{
          this.openToastNotificationForMessage(error.response.data.error.message, 'error')
        }
      })
    },
    // Check for nominator budget cap
    checkBudgetsCap (tabId) {
      let members = this.getMemberPins
      if (this.selectedAwardDetails?.rewardStyle === 'plateau' || this.selectedAwardDetails?.monetarySetup === 'fixed' || this.selectedAwardDetails?.monetarySetup === 'none') {
        this.checkForNominationPrevention(tabId)
        return
      }
      NominationApi.checkBudgetCap(this.selectedAwardFamily, members, this.selectedAward)
      .then(response => {
        let hasError = this.checkForErrorMessages(response)
        if (!hasError) {
          if ((this.isQuestionForAwardValue && this.awardDetails.award_points === '') || this.awardDetails.description === '' || this.awardDetails.message === '') {
            this.isButtonDisabled = true
          } else {
            this.isButtonDisabled = false
          }
          this.checkForNominationPrevention(tabId)
        }
      })
      .catch(error => {
        this.showLoader = false
        if(error.response.status == 401){
          this.tab = 401;
        }else{
          this.openToastNotificationForMessage(error.response.data.error.message, 'error')
        }
      })
    },
    checkForNominationPrevention (tabId) {
      let members = this.getMemberPins
      NominationApi.checkPreventNomination(this.selectedAwardFamily, members, this.selectedAward, this.awardDetails.award_points || 0)
      .then(response => {
        this.checkForErrorMessages(response)
        if (response.data) {
          if (response.data.stepBack || response.data.preventNomination) {
            this.tab = 3
            return
          } else {
            this.tab = tabId
          }
        }
      })
      .catch(error => {
        this.tab = this.isAwardDetailsPreSelected ? 2 : 3
        this.openToastNotificationForMessage(error.response.data.error.message, 'error')
      })
    },
    indicatorClickHandler (id) {
      if ((id - 1) === this.tab || id < this.tab) {
        if (id === 2 && !this.selectedAwardFamily) {
          this.openToastNotificationForMessage(this.getTranslations("Please select an award family") || this.$tc('message.indicatorValidations', 0), 'error')
          return
        }
        if (id === 3 && this.selectedMembers.length === 0) {
          this.openToastNotificationForMessage(this.getTranslations("Please select atleast 1 nominee") || this.$tc('message.indicatorValidations', 1), 'error')
          return
        }
        if (id === 4 && !this.selectedAward) {
          this.openToastNotificationForMessage(this.getTranslations("Please select an award") || this.$tc('message.indicatorValidations', 2), 'error')
          return
        }
        this.moveNext(id, id < this.tab)
      }
    }
  },
  beforeDestroy () {
    clearInterval(this.scrollHeightCheckInterval)
    clearInterval(this.continueSessionInterval)
    window.onresize = () => {}
  }
}
</script>

<style lang="scss">
.screen1-enter {
	transform: translateX(10px);
	opacity: 0;
}
.screen1-enter-active {
	transition: all .5s ease-in-out;
}
.screen1-leave-active, .screen1-leave-to {
	opacity: 0;
}
.screen2-enter {
	width: 30%;
	opacity: 0;
}
.screen2-enter-active {
	transition: all .5s ease-in-out;
}
.screen2-leave-active, .screen2-leave-to {
	opacity: 0;
}
.screen2-enter-to {
	width:100%;
	opacity: 1;
	margin-left: auto;
}
.screen3-enter {
	width: 30%;
	opacity: 0;
}
.screen3-enter-active {
	transition: all .5s ease-in-out;
}
.screen3-leave-active, .screen3-leave-to {
	opacity: 0;
}
.screen3-enter-to {
	width:100%;
	opacity: 1;
	margin-left: auto;
}
.screen4-enter {
	width: 30%;
	opacity: 0;
}
.screen4-enter-active {
	transition: all .5s ease-in-out;
}
.screen4-leave-active, .screen4-leave-to {
	opacity: 0;
}
.screen4-enter-to {
	width:100%;
	opacity: 1;
	margin-left: auto;
}
.v-toast__icon {
  display: none !important;
}
.carousel-indicators li {
  cursor: pointer;
}

</style>
