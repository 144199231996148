<template>
  <div class="row">
        <!-- Questions for award value -->
        <div class="col-12 pt-3" v-for="(data, index) in awardValues" :key="index"> 
            <div class="radiogroupdiv">
                <p class="h5">
                  {{data.Question.Translations.en.question}}
                </p>
                <div class="form-check border-bottom" v-for="(award, awardIndex) in data.Answers" :key="awardIndex">
                    <input  class="form-check-input" 
                            type="radio" 
                            :name="`exampleRadios${index}`" 
                            :id="`exampleRadios1${awardIndex}${index}`" 
                            :value="award.answerId"
                            :checked="checkForAwardValue(award, data)"
                            @change="updateEmitForAwardValue(award, data)"
                            >
                    <label class="form-check-label" :for="`exampleRadios1${awardIndex}${index}`">
                    {{award.Answer.Translations !== undefined ? award.Answer.Translations.en.answer : award}}
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
  data () {
    return {
      selectedAward: {},
      awardValues: []
    }
  },
  mounted () {
    this.awardValues = this.$store.getters.questionsForAwardValue
  },
  computed: {
    validateAwardValueAnswer () {
      return this.awardValues.filter(val => {
        return val.selectedAnswer !== null
      })
    }
  },
  methods: {
    checkForAwardValue (answers, question) {
      let vqa = this.$store.getters.vqa
      return vqa[question.id] === answers.id
    },
    updateEmitForAwardValue (data, award) {
      award.selectedAnswer = data.id
      let vqa = {...this.$store.getters.vqa}
      vqa[award.id] = data.id
      this.$store.dispatch('updateVQA', vqa)
      this.$store.dispatch('setAwardValueQuestions', this.awardValues)
      if (this.validateAwardValueAnswer.length === this.awardValues.length) {
        this.checkForTheAnswer()
      }
    },
    checkForTheAnswer () {
      this.$emit('enableNextButton')
    }
  }
}
</script>