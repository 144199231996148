<template>
    <div class="row justify-content-center">
      <div class="col-10 text-center ">
        <h2 class="m-5">
          {{ awardSentTitle }}
        </h2>
        <i class="fa fa-check-circle text-success result-icon"></i>
        <p class="text-secondary mt-4" >
          {{ confirmationNote }}
        </p>
        <div style="height:38px">
        </div>
        <button
          type="button"
          class="btn btn-primary d-block w-100 text-upper nextbtn"
          @click.prevent="submitNewAward">
          {{ submitAnotherAward }}
        </button>
        </div>
    </div>
</template>

<script>
  import getTranslation from "@/mixin/translations.js"
  export default {
    mixins: [getTranslation],
    props: ['isAwardSent'],
    methods: {
      submitNewAward () {
        this.$emit('submitNewAward')
      }
    },
    computed: {
      awardSentTitle () {
        return this.getTranslations("awardSent") || this.$tc("message.resultHeader", 1)
      },
      confirmationNote () {
        return this.getTranslations("emailConfirmation") || this.$tc("message.resultBody", 0)
      },
      submitAnotherAward () {
        return this.getTranslations("submitAnother") || this.$t('message.newAward')
      }
    }
}
</script>

<style>

</style>