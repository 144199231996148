const flags = {
  DISPLAY_DESCRIPTION_OF_ACHIEVEMENT: 32,
  DESCRIPTION_OF_ACHIEVEMENT_IS_MANDATORY: 64,
  DISPLAY_MESSAGE_TO_RECIPIENT: 128,
  MESSAGE_TO_RECIPIENT_IS_MANDATORY: 256
}

export const displayDescriptionAndMessage = (award) => {
  let obj = {
    displayDescription: false,
    descriptionIsMandatory: false,
    displayMessage: false,
    messageIsMandatory: false
  }
  if (!award || !award.flags) return obj
  let totalFlags = award.flags

  if (totalFlags - flags.MESSAGE_TO_RECIPIENT_IS_MANDATORY >= 0) {
    totalFlags = totalFlags - flags.MESSAGE_TO_RECIPIENT_IS_MANDATORY
    obj.messageIsMandatory = true
  }
  if (totalFlags - flags.DISPLAY_MESSAGE_TO_RECIPIENT >= 0) {
    totalFlags = totalFlags - flags.DISPLAY_MESSAGE_TO_RECIPIENT
    obj.displayMessage = true
  }
  if (totalFlags - flags.DESCRIPTION_OF_ACHIEVEMENT_IS_MANDATORY >= 0) {
    totalFlags = totalFlags - flags.DESCRIPTION_OF_ACHIEVEMENT_IS_MANDATORY
    obj.descriptionIsMandatory = true
  }
  if (totalFlags - flags.DISPLAY_DESCRIPTION_OF_ACHIEVEMENT >= 0) {
    obj.displayDescription = true
  }
  return obj
}

const familyFlags = {
  DO_NOT_DISPLAY_NOMINATOR_NAME: 16,
  ALLOW_TO_DELAY_ISSUANCE_DATE: 1
}

export const allowToDelayIssuanceDate = (family) => {
  if (!family) return false

  let totalFlags = family.flags

  if (totalFlags === (familyFlags.DO_NOT_DISPLAY_NOMINATOR_NAME + familyFlags.ALLOW_TO_DELAY_ISSUANCE_DATE) || totalFlags === (familyFlags.ALLOW_TO_DELAY_ISSUANCE_DATE)) {
    return true
  }
  return false
}