<template>
    <div class="row" id="scroll-row">
        <!-- Input To Search User -->
        <div class="col-12 m-top">            
            <div class="autocomplete" ref="autocomplete">
                <input type="text" 
                  @keydown.down="toggleCurrentFocusDown"
                  @keyup.up="toggleCurrentFocusUp"
                  @keypress.enter="selectedMemberFromEnter"
                  @keyup.esc="closeDropdownHandler"
                  @blur="closeDropdownHandler"
                  @focus="openDropDownHandler"
                  :class="closeDropdown ? 'border-bottom' : ''"
                  :placeholder="placeholderForAutocomplete"
                  v-model="searchText">
                  <i class="fa fa-search search-icon text-secondary"></i>
                <div v-if="!closeDropdown" class="dropdown" id="dropdown-custom">
                  <template v-if="members.length === 0">
                    <div
                     class="dropdown-item d-flex justify-content-between align-items-center"
                     :class="!membersSearchDone ? 'gray-text' : ''">
                      {{ noResultFoundText }}
                      <div class="search-loader" v-if="!membersSearchDone">Loading...</div>
                    </div>
                  </template>
                  <div
                    v-else
                    class="dropdown-item"
                    v-for="(data, index) in members"
                    :key="index"
                    @mouseover="currentFocus = index"
                    :class="currentFocus === index ? 'dropdown-focus' : ''"
                    @click.prevent="selectedMember(data)">
                    {{data.fullName}}
                  </div>
                </div>
            </div>
        </div>
        <!-- <div v-if="noEmployeesFound" class="emp-warn">
          <em class="fal fa-info-circle mr-1"></em>
          {{ $t('message.noEmployeesFound') }}
        </div> -->
        <!-- Selected Users -->
        <div class="col-12" v-if="selected_members.length > 0">
            <ul class="selection">
                <li v-for="(member, memberIndex) in selected_members" :key="memberIndex">
                    <div class="nameinfo">
                        <div class="shortname bg-primary">{{member.fName !== undefined ? member.fName.substr(0, 1).toUpperCase() : ''}}{{member.lName !== undefined ? member.lName.substr(0, 1).toUpperCase() : ''}}</div>
                        <span class="fullname default-text">{{member.fullName}}</span>
                    </div>
                    <button @click="removeItem(memberIndex)" class="invis-btn">
                      <i class="fa fa-times text-secondary ml-2"></i>
                    </button>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import 'vue-search-select/dist/VueSearchSelect.css';
import { ModelListSelect } from 'vue-search-select';
import eventEmit from '@/mixin/index.js'
import debounceMixin from '@/mixin/debounce'
import getTranslation from "@/mixin/translations.js"

export default {
  components:{
      'model-list-select': ModelListSelect,
  },
  mixins: [eventEmit, debounceMixin, getTranslation],
  data() {
      return {
          selectMember: {},
          debounceMethod: () => {},
          searchText: '',
          closeDropdown: true,
          currentFocus: null
      }
  },
  props: ['members', 'selected_members', 'searchMemberText', 'noEmployeesFound', 'membersSearchDone'],
  mounted () {
    this.sendResizeContent(false, true)
    this.debounceMethod = this.debounce(function () {
      this.closeDropdown = false
      this.$emit('update:searchMemberText', this.searchText ? this.searchText.trim() : "")
    }, 500)
  },
  watch: {
    searchText: {
      handler () {
        if (this.searchText && String(this.searchText).trim() !== '') {
          this.debounceMethod()
        }
      }
    }
  },
  computed: {
    noResultFoundText () {
      if (!this.membersSearchDone) {
        return this.getTranslations("searchingForMembers") || this.$t('message.searchingForMembers')
      }
      return this.getTranslations("noResultFound") || this.$t('message.noResultFound')
    },
    placeholderForAutocomplete () {
      return this.getTranslations("searchMembers") || this.$t('message.autocompletePlaceholder')
    }
  },
  methods:{
    openDropDownHandler () {
      if (this.searchText || this.members.length > 0) {
        this.closeDropdown = false
      }
    },
    closeDropdownHandler () {
      setTimeout(() => {
        this.closeDropdown = true
        this.searchText = ''
      },200)
    },
    toggleCurrentFocusDown () {
      if (!this.closeDropdown) {
        let elem = document.getElementById("dropdown-custom")
        let item = document.getElementsByClassName("dropdown-item")
        let itemHeight = 0
        if (item.length > 0) {
          itemHeight = item[0].clientHeight
        }
        if (this.currentFocus !== null && this.currentFocus < this.members.length - 1) {
          this.currentFocus += 1
        } else {
          this.currentFocus = 0
        }
        if (this.currentFocus > 0) elem.scrollTop = elem.offsetTop + (itemHeight * (this.currentFocus - 3))
      }
    },
    toggleCurrentFocusUp () {
      if (!this.closeDropdown) {
        let elem = document.getElementById("dropdown-custom")
        let item = document.getElementsByClassName("dropdown-item")
        let itemHeight = 0
        if (item.length > 0) {
          itemHeight = item[0].clientHeight
        }
        if (this.currentFocus !== null && this.currentFocus > 0) {
          this.currentFocus -= 1
        } else {
          this.currentFocus = 0
        }
        if (this.currentFocus > 0) elem.scrollTop = elem.offsetTop + (itemHeight * (this.currentFocus - 3))
      }
    },
    selectedMember (data) {
      this.closeDropdown = true
      this.searchText = ''
      this.currentFocus = null
      this.$emit('update-member', {type: 'add', index: 0, member: data});
    },
    selectedMemberFromEnter () {
      this.selectedMember(this.members[this.currentFocus])
    },
    openDropdown () {
      if (!document) return
      let x = document.querySelector(".selection .menu")
      if (x.getAttribute("class") !== "menu visible") {
        x.setAttribute("class", "menu visible")
        x.style.display = "block"
      }
      // document.getElementById("addmembers").
    },
      /**
       * Move To Next Tab
       */
      moveToNext(){
        this.$emit('next', {tab: '2'});
      },

      /**
       * OnSelect
       */
      onselectItem(data){
        if (data.pin !== undefined) {
          this.$emit('update-member', {type: 'add', index: 0, member: data});
          this.selectMember = null;
        }
      },
      selectFromParentComponent1 (event) {
        // select option from parent component
        this.$emit('update:searchMemberText', document.getElementById('addmembers').value)
      },
      /**
       * Remove Member
       */
      removeItem(itemIndex){
          this.$emit('update-member', {type: 'remove', index: itemIndex, member: {}});
      }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.autocomplete {
  width: 100%;
  padding: 0;
  height: inherit;
  position: relative;
  display: grid;
  border-radius: .25rem;
  border: 2px solid #d8d8d8;
}
.autocomplete:focus-within {
  border: 2px solid var(--primary);
}
.autocomplete ul li {
  margin: 0;
  list-style-type:none;
}
.autocomplete input {
  border: none !important;
  margin: 0;
  padding: 10px;
  width: 100%;
  outline: none !important;
  display: block;
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}
.border-bottom {
  border-bottom-left-radius: .25rem;
  border-bottom-right-radius: .25rem;
}
.dropdown {
  min-height: 0px;
  max-height: 300px;
  overflow: auto;
  z-index: 13;
  position: absolute;
  top: 43px;
  background: white;
  width: calc(100% + 4px);
  display: block;
  border: 2px solid var(--primary);
  border-top: 0;
  margin: 0 0px 0 -2px;
  border-bottom-left-radius: .25rem;
  border-bottom-right-radius: .25rem;
}
.dropdown-item {
  padding: 7px 10px;
  border-top: 1px solid #f4f4f4;
  overflow: hidden;
}
.dropdown-focus {
  background: #f4f4f4;
}
.invis-btn {
  outline: none !important;
  border: none !important;
  background: transparent;
}
.invis-btn:active, .invis-btn:focus, .invis-btn:enabled {
  outline: none;
  border: none;
}
.emp-warn {
  padding: 15px;
  background: #d9edf7;
  margin: 15px;
  width: 100%;
  border: #bce8f1 1px solid;
  color: #31708f;
}

.disable-scroll::-webkit-scrollbar {
    display: none;
}
</style>
