<template>
    <div class="row">
        <div class="col-12">
            <!-- Enter Points To Award -->
            <div v-if="this.selectedAwardDetails.awardPrice" class="form-group" :class="awardErrors.points ? 'error': ''">
              <label for="exampleInputEmail1">
                {{ inputAwardPoints }} {{ rangeDescription }}
              </label>
                  <div class="radiogroupdiv" v-if="isAwardLevelType">
                    <div class="form-check border-bottom" v-for="(awardPrice, awardIndex) of selectedAwardDetails.awardPrice" :key="awardIndex">
                      <input class="form-check-input"
                          type="radio"
                          name="exampleRadios"
                          :id="`exampleRadios${awardIndex}`"
                          :value="awardPrice"
                          :checked="(formData.award_points == awardPrice) ? true : false"
                          @change="setSelectedAwardPrize(awardPrice)"
                      >
                      <label class="form-check-label" :for="`exampleRadios${awardIndex}`">
                        {{awardPrice}}
                      </label>
                    </div>
                  </div>
                <input 
                  v-else
                  :readonly="isAwardFixedType || selectedAwardDetails.monetarySetup === 'plateau'"
                  v-model.number="formData.award_points"
                  type="number"
                  @input="validatePoints"
                  class="form-control"
                  id="exampleInputEmail1">
                <p v-show="false">{{formData.award_points}}</p>
                <p v-if="awardErrors.points && isAwardRangeType" class="errorspan">{{$tc("message.awardDetailsValidation", 0)}} {{rangeDescription}}</p>
                <p v-else class="errorspan">{{$tc("message.awardDetailsLevelValidation", 0)}}</p>
            </div>

            <div class="form-group" v-if="allowAttachments">
                <label>{{ uploadVideoText }}</label>
                <div class="h6 text-secondary">
                  {{ uploadLimitText }}
                </div>
                <div></div>
                <input 
                  @change="getSelectedFile" @click="$refs.paperclipButton.blur();" type="file" ref="fileInput" class="form-control-file form-control" style="display:none" id="exampleFormControlFile1">
                <button
                  ref="paperclipButton"
                  type="button"
                  class="btn btn-secondary"
                  v-if="uploadedVideoData === null"
                  @click="$refs.fileInput.click();">
                  <em class="fal fa-paperclip"></em>
                </button>
                <button
                  type="button"
                  class="btn btn-secondary ml-2"
                  v-show="!showVideoRecorder && uploadedVideoData === null"
                  @click="showVideoRecorder = true">
                  <em class="fal fa-camera"></em>
                </button>
                <button
                  v-show="showVideoRecorder && uploadedVideoData === null"
                  type="button"
                  @click="showVideoRecorder = false"
                  class="btn btn-secondary ml-2">
                  <em class="fal fa-video-slash"></em>
                </button>
                  <div v-if="uploadedVideoData === null">
                    <div>
                      <videoRecorder
                        class="mt-2"
                        v-if="showVideoRecorder"
                        :recordedData="recordedData"
                        @recordingStarted="showUploadButton = false"
                        @recordedVideoFile="recordedVideoFileHandler"/>
                    </div>
                    <button
                        v-if="showUploadButton && showVideoRecorder"
                        type="button"
                        class="btn btn-primary mt-2"
                        @click="uploadRecordedFile">
                        Upload file
                    </button>
                  </div>
                <!-- Description of video uploading -->
                <div v-if="uploadedVideoData !== null" class="uploaded_file">
                  <p class="m-0 primary-text" v-if="isUploading">
                    {{ uploadingVideoText }}
                  </p>
                  <p v-else class="m-0 primary-text"> {{ isDeleting ? deletingVideo : uploadedText }}</p>
                  <button @click="deleteVideo" class="remove_file_icon">
                    <em class="fa fa-times secondary-text" v-if="!isUploading"></em>
                  </button>
                </div>
                <!-- Progress Bar -->
                <div class="progress" v-if="isUploading">
                  <div class="progress-bar" id="progress-bar" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
            </div>

            <div
              class="form-group"
              :class="awardErrors.description ? 'error': ''"
              v-if="displayDescription">
                <label for="award_description">{{ descriptionMessage }}</label>
                <textarea 
                  v-model="formData.description" class="form-control award_description" id="award_description" rows="4" ></textarea>
                <p class="limit-hint" v-if="selectedAwardDetails.descriptionAchievementLimit">({{ formData.description ? formData.description.length : 0 }} / {{this.selectedAwardDetails.descriptionAchievementLimit}} {{ charactersText }})</p>
                <p 
                  class="errorspan"
                  v-if="awardErrors.description">
                  {{ $tc("message.awardDetailsValidation", 1) }}
                </p>
            </div>

            <div 
              class="form-group"
              :class="awardErrors.message ? 'error': ''"
              v-if="displayMessage">
                <label 
                  for="award_message">
                  {{ nomineeMessage }}
                </label>
                <textarea 
                  type="text" rows="5" class="form-control award_description" v-model="formData.message" id="award_message" aria-describedby="emailHelp" placeholder="" />
                  <p class="limit-hint" v-if="selectedAwardDetails.messageRecipientLimit">({{ formData.message ? formData.message.length : 0 }} / {{this.selectedAwardDetails.messageRecipientLimit}} {{ charactersText }})</p>
                <p 
                  class="errorspan" v-if="awardErrors.message">{{$tc("message.awardDetailsValidation", 2)}}</p>
            </div>
            <div class="form-group d-grid" :class="awardErrors.date ? 'error': ''" v-if="allowIssuanceDateToBeDelayable">
              <label for="enable_issuance_date" class="checkbox-label">
                <input
                  v-model="enableDate"
                  @change="enableDatepicker"
                  type="checkbox"
                  class="mr-2 checkbox-input"
                  id="enable_issuance_date">
                  {{ issuanceDateMessage }}
              </label>
              <div>
                <date-picker
                  :class="awardErrors.date ? 'error-border': ''"
                  v-if="enableDate"
                  id="award_date"
                  min="2016-06-15"
                  v-model="formData.awardForDate"
                  @change="validateDate"
                  valueType="format"
                  ></date-picker>
                  <p class="errorspan" v-if="awardErrors.date">
                    {{ dateErrorMessage }}
                  </p>
              </div>
            </div>
        </div>
    </div>
</template>

<script>
import videoRecorder from './videoRecorder'
import NominationApi from '@/service/api.js'
import eventEmit from '@/mixin/index.js'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import getTranslation from "@/mixin/translations.js"

export default {
  components: {
    videoRecorder,
    DatePicker
  },
  mixins: [eventEmit, getTranslation],
  props: ['formData', 'showLoader', 'selectedAwardDetails', 'uploadedVideoDetails', 'enableDatePickerToggle', 'checksForAwardDescriptionAndMessage', 'tabId'],
    data() {
      return {
        showErrorForPoints: true,
        isRecording: false,
        isDeleting: false,
        isUploading: false,
        showVideoRecorder: false,
        uploadedVideoData: null,
        showUploadButton: false,
        recordedData: null,
        enableDate: false,
        awardErrors: {
          points: false,
          description: false,
          file: false,
          message: false,
          date: false
        },
        uploadFailedCount: 0
      }
    },
    watch: {
      selectedAwardDetails: {
        handler () {
          if (this.isAwardFixedType) {
            this.formData.award_points = Number(this.selectedAwardDetails.awardPrice.fixed)
          }
          if (this.formData.award_points > 0) {
            this.showErrorForPoints = false
          }
          if (this.selectedAwardDetails.monetarySetup === "plateau") {
            this.selectedAwardDetails.awardPrice = this.selectedAwardDetails.creditValue
            this.formData.award_points = this.selectedAwardDetails.creditValue
          }
        },
        immediate: true,
        deep: true
      },
      uploadedVideoDetails: {
        handler () {
          if (!this.uploadedVideoDetails) {
            this.uploadedVideoData = this.uploadedVideoDetails
          }
        },
        deep: true,
        immediate: true
      },
      tabId: {
        handler () {
          if (this.tabId !== 4) {
            this.awardErrors.points = false
            this.awardErrors.description = false
            this.awardErrors.file = false
            this.awardErrors.message = false
            this.awardErrors.date = false
          }
        }
      },
      enableDate: {
        handler () {
          if (this.enableDate) {
            this.awardErrors.date = new Date(new Date().setDate(new Date().getDate() - 1)) > new Date(this.formData.awardForDate)
          } else {
            this.awardErrors.date = false
          }
        }
      }
    },
    computed: {
      charactersText () {
        return this.$store.getters.translations.characters || this.$t('message.characters')
      },
      deletingVideo () {
        return this.$t('message.deletingVideo')
      },
      inputAwardPoints () {
        if (this.isAwardLevelType) {
          return this.getTranslations("selectPoints") || this.$tc("message.awardDetailLevelSubHeaders", 0)
        }
        return this.getTranslations("enterPoints") || this.$tc("message.awardDetailSubHeaders", 0)
      },
      uploadingVideoText () {
        return `${this.getTranslations("uploading") || this.$tc("message.awardDetailsHints", 1)} ${this.uploadedVideoData.key}`
      },
      uploadedText () {
        return this.getTranslations("uploadedVideo") || this.$tc("message.awardDetailsHints", 3)
      },
      uploadVideoText () {
        return this.getTranslations("uploadVideo") || this.$tc("message.awardDetailSubHeaders", 1)
      },
      uploadLimitText () {
        return `${this.getTranslations("videoUploadLimit") || this.$t('message.uploadingLimit')} ${process.env.VUE_APP_ALLOWED_VIDEO_UPLOAD_SIZE} MB`
      },
      descriptionMessage () {
        return this.getTranslations("achievementDescription") || this.$tc("message.awardDetailSubHeaders", 2)
      },
      nomineeMessage () {
        return this.getTranslations("messageForNominees") || this.$t("message.recipientMessage")
      },
      issuanceDateMessage () {
        return this.getTranslations("selectDate") || this.$t("message.selectDate")
      },
      allowAttachments () {
        return this.checksForAwardDescriptionAndMessage.allowAttachments
      },
      displayDescription () {
        return this.checksForAwardDescriptionAndMessage.displayDescription
      },
      allowIssuanceDateToBeDelayable () {
        return this.checksForAwardDescriptionAndMessage.enableDate
      },
      displayMessage () {
        return this.checksForAwardDescriptionAndMessage.displayMessage
      },
      showCameraIcon () {
        return !this.showVideoRecorder ? 'fal fa-camera' : 'fal fa-video-slash'
      },
      dateErrorMessage () {
        let messageIndex = this.formData.awardForDate ? 2 : 1
        return this.$tc("message.dateError", messageIndex)
      },
      isAwardFixedType () {
        return this.selectedAwardDetails.monetarySetup === "fixed"
      },
      isAwardLevelType () {
        return this.selectedAwardDetails.monetarySetup === "levels"
      },
      isAwardRangeType () {
        return this.selectedAwardDetails.monetarySetup === "range"
      },
      rangeDescription () {
        if (!this.isAwardFixedType && !this.isAwardLevelType && this.selectedAwardDetails.awardPrice && this.selectedAwardDetails.monetarySetup !== 'plateau') {
          return `(${this.selectedAwardDetails.awardPrice.min} - ${this.selectedAwardDetails.awardPrice.max})`
        } else {
          return ''
        }
      }
    },
    mounted () {
      this.enableDate = this.enableDatePickerToggle
      this.$root.$on('validateFormData', () => {
        this.validateAwardDetailsHandler()
      })
      this.$root.$on('deleteUploadedVideo', () => {
        if (this.uploadedVideoData) {
          this.deleteVideo()
        }
      })
      this.sendResizeContent(false, true)
    },
    methods: {
      uploadRecordedFile () {
        if (this.recordedData) {
          this.uploadVideo(this.recordedData)
        }
      },
      enableDatepicker () {
        this.$emit("enableDatepicker", this.enableDate)
      },
      setSelectedAwardPrize(awardPrice){
        this.formData.award_points = Number(awardPrice);
      },
      validateDate () {
        if (this.enableDate) {
          this.awardErrors.date = !this.formData.awardForDate
          if (this.formData.awardForDate) {
            this.awardErrors.date = new Date(new Date().setDate(new Date().getDate() - 1)) > new Date(this.formData.awardForDate)
          }
        } else {
          this.awardErrors.date = false
        }
      },
      validateAwardDetailsHandler () {
        if (this.isAwardRangeType) {
          this.awardErrors.points = this.formData.award_points < this.selectedAwardDetails.awardPrice.min || this.formData.award_points > this.selectedAwardDetails.awardPrice.max
        }
        if (this.isAwardLevelType) {
          this.awardErrors.points = this.formData.award_points.toString().trim() === ''
        }
        this.awardErrors.description = this.checksForAwardDescriptionAndMessage.descriptionIsMandatory ? this.formData.description.trim() === '' : false
        this.awardErrors.message = this.checksForAwardDescriptionAndMessage.messageIsMandatory ? this.formData.message.trim() === '' : false
        
        this.validateDate()
        let dateIsValid = !this.enableDate || (this.enableDate && !this.awardErrors.date)
        if (!this.awardErrors.points && !this.awardErrors.description && !this.awardErrors.message && dateIsValid) {
          this.$emit('validForm', true)
        }
      },
      getSelectedFile (event) {
        this.uploadVideo(event.target.files[0])
      },
      validatePoints () {
        if (this.formData.award_points > 0) {
          this.showErrorForPoints = false
        }
      },
      uploadVideo (file) {
        let fileSize = process.env.VUE_APP_ALLOWED_VIDEO_UPLOAD_SIZE ? process.env.VUE_APP_ALLOWED_VIDEO_UPLOAD_SIZE : 15
        if ((file.size / 1048576) > Number(fileSize)) {
          this.$toast.open({
              message: this.$t('message.fileErrorMessage', {
                size: fileSize
              }),
              type: "error",
              position: "top-right"
            })
          return
        }
        this.showUploadButton = false
        this.$emit("uploadingVideo")
        this.isUploading = true
        this.uploadedVideoData = {
          key: file.name
        }
        NominationApi.uploadVideo(file, "progress-bar")
        .then(response => {
          this.uploadFailedCount = 0
          this.showVideoRecorder = false
          this.isUploading = false
          this.uploadedVideoData = response.data
          this.formData.fileName = response.data.Key
          this.$emit("videoUploaded", response.data)
        })
        .catch(error => {
          this.uploadFailedCount += 1
          this.isUploading = false
          this.uploadedVideoData = null
          if (this.uploadFailedCount > 2) {
            this.$toast.open({
              message: this.$t('message.contactAdministrator'),
              type: "error",
              position: "top-right"
            })
          }
          if (error.response.status === 422) {
            let message = error.response.data.error ? error.response.data.error.message : error.response.data.message
            this.$toast.open({
              message: message,
              type: "error",
              position: "top-right"
            })
          } else if (error.response.status === 500) {
            this.$emit('videoApiFailed')
          }
        })
      },
      recordedVideoFileHandler (data) {
        if (data !== undefined) {
          this.showUploadButton = true
          this.recordedData = data
        }
      },
      deleteVideo () {
        this.$emit('update:showLoader', true)
        this.isDeleting = true
        NominationApi.deleteUploadedVideo(this.uploadedVideoData.Key)
        .then(response => {
          this.$emit('update:showLoader', false)
          this.uploadedVideoData = null
          this.formData.fileName = ''
          this.isDeleting = false
        })
        .catch(error => {
          this.$emit('update:showLoader', false)
          this.isDeleting = false
          if (error.response.status === 500) {
            this.$emit('videoApiFailed')
          }
        })
      }
    },
    beforeDestroy () {
      this.$root.$off('validateFormData')
      this.$root.$off('deleteUploadedVideo')
    }
}
</script>

<style>
.progress_wraper {
  background: rgb(226, 238, 241);
  margin: 10px 0;
  height: 10px;
  width: 100%;
}
.progress_bar {
  width: 50%;
  height: 100%;
  background: var(--primary) !important;
}
.uploaded_file {
  margin: 10px 0px 0px;
  outline: 1px solid gray;
  font-size: 15px;
  display: flex;
  align-items: center;
  padding: 10px;
  position: relative;
}
.remove_file_icon {
  position: absolute !important;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  outline: none;
  border: none;
}
.remove_file_icon:active, .remove_file_icon:focus {
  outline: none;
  border: none;
}
.checkbox-input {
  height: 18px;
  width: 18px;
}
.checkbox-label {
  display: flex;
  align-items: center;
}
.mx-datepicker {
  width: 100%;
}

.mx-input{
    display: block;
    width: 100%;
    height: 48px;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    outline: none;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.mx-input:focus-within {
  border-color: #fff;
  box-shadow: 0 0 4px var(--primary);
  outline: 0 none;
}
.award_description:focus-within {
  outline: none !important;
    border: 2px solid var(--primary) !important;
    box-shadow: 0 0 1px var(--primary);
}
.error-border .mx-input {
  border-color: var(--danger);
  box-shadow: 0 0 4px var(--danger);
  outline: 0 none;
}
.limit-hint {
  font-size: 12px;
  text-align: right;
  color: #737373;
  margin-bottom: 0px;
  margin-top: 5px
}
</style>