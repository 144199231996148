<template>
  <div class="main-award-desc-accordion-container">
    <button @click="toggleAccordion()" class="" :aria-expanded="isOpen">
      <img src="@/assets/images/chevron-down.svg" alt="" class="chevron-down" :class="{ arrowRotate : isOpen }">
    </button>
    <transition name="slide">
    	<div v-show="isOpen" :id="`collapse${_uid}`" class="award-description">
    	  <slot name="content" />
    	</div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style lang="scss" scoped>
	.main-award-desc-accordion-container {

		width: 100%;

		button {
			background: none;
			outline: none;
			border: none;
			position: absolute;
			top: 35px;
			right: 105px;
		}

		img.chevron-down {
			width: 20px;
			margin-left: 10px;
			transition: all 0.25s ease;

			&.arrowRotate { transform: rotate(180deg); }
		}
	}

	.award-description {
	  margin: 0;
	  padding: 0;
	  transform-origin: top;
	  transition: transform .4s ease-in-out;
	  overflow: hidden;
	  max-width: 80%;
	  z-index: 1000;
	}

	.slide-enter-active,
	.slide-leave-active {
	  transition: all 0.25s;
	  max-height: 1000px;
	}
	.slide-enter, .slide-leave-to{
	  opacity: 0;
	  max-height: 0px;
	}

	@media only screen and  (max-width: 768px) {
		.main-award-desc-accordion-container {
			button {
				top: 35px;
				right: 60px;
			}
		}
	}

</style>