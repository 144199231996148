import NominationApi from '@/service/api'
export default {
  data () {
    return {
      isAwardFamilyPreSelected: false,
      isAwardDetailsPreSelected: false
    }
  },
  methods: {
    checkPreselectedData () {
      let preSelectedData = this.$store.getters.preSelectedAwardIds
      if (preSelectedData) {
        if (preSelectedData.awardFamilyId) {
          this.selectedAwardFamily = preSelectedData.awardFamilyId
        }
        if (preSelectedData.awardId) {
          this.isAwardDetailsPreSelected = true
          this.selectedAward = preSelectedData.awardId
          this.getAwardDetails(preSelectedData.awardId, preSelectedData.awardFamilyId)
        } else {
          this.isAwardFamilyPreSelected = true
          if (preSelectedData.awardFamilyId) {
            this.getAwardFamilyDetails(preSelectedData.awardFamilyId)
          }
        }
      }
    },
    async getAwardFamilyDetails (familyId) {
      try {
        this.showLoader = true
        const details = await NominationApi.getAwardFamilyDetails(familyId)
        let canNominate = await this.canNominateWithSelectedFamily()
        if (canNominate) {
          this.showLoader = false
          this.$store.dispatch('setPreselectedAwardFamily', details.data)
          this.selectedAwardFamilyDetails = {
            member: details.data
          }
          this.tab = 2
          if (this.selectedMembers.length === 0) {
            this.isButtonDisabled = true
          } else {
            this.isButtonDisabled = false
          }
        } else {
          throw Error('Cannot nominate for given family id ' + familyId)
        }
      } catch (error) {
        this.tab = 1
        this.selectedAwardFamilyDetails = null
        this.isAwardFamilyPreSelected = false
        this.$store.dispatch('setPreselectedAwardFamily', null)
        this.$store.dispatch('setPreselectedAwardIds', null)
        this.showLoader = false
        this.getAwardFamily()
        console.log(error)
      }
    },
    async getAwardDetails (awardId, familyId) {
      try {
        this.showLoader = true
        let details = await NominationApi.getAwardDetails(awardId)
        this.selectedAwardDetails = details.data
        this.$store.dispatch('setPreselectedAward', details.data)
        this.showLoader = false
        this.isAwardFamilyPreSelected = true
        this.selectedAwardFamily = details.data.familyId
        this.getAwardFamilyDetails(details.data.familyId)
      } catch (error) {
        this.isAwardDetailsPreSelected = false
        this.selectedAward = ''
        this.$store.dispatch('setPreselectedAwardIds', null)
        this.$store.dispatch('setPreselectedAward', null)
        this.showLoader = false
        if (familyId) {
          this.getAwardFamilyDetails(familyId)
        } else {
          this.tab = 1
          this.getAwardFamily()
        }
        console.log(error)
      }
    }
  }
}