export default {
  methods: {
    checkForErrorMessages (response) {
      let doesHaveErrors = false
      if (response.data.errors) {
        for (let error of response.data.errors) {
          doesHaveErrors = true
          let msg = error.msg
          if (Array.isArray(error.params)) {
            for (let param of error.params) {
              for (let [key, value] of Object.entries(param)) {
                msg = msg.replace(key, value)
              }
            }
          } else {
            for (let [key, value] of Object.entries(error.params)) {
              msg = msg.replace(key, value)
            }
          }
          this.openToastNotificationForMessage(msg, 'error')
        }
      }
      if (response.data.warnings) {
        for (let warn of response.data.warnings) {
          let msg = String(warn.msg)
          if (Array.isArray(warn.params)) {
            for (let param of warn.params) {
              for (let [key, value] of Object.entries(param)) {
                msg = msg.replace(key, value)
              }
            }
          } else {
            for (let [key, value] of Object.entries(warn.params)) {
              msg = msg.replace(key, value)
            }
          }
          this.openToastNotificationForMessage(msg, 'warning')
        }
      }
      if (response.data.success) {
        for (let success of response.data.success) {
          let msg = String(success.msg)
          if (Array.isArray(success.params)) {
            for (let param of success.params) {
              for (let [key, value] of Object.entries(param)) {
                msg = msg.replace(key, value)
              }
            }
          } else {
            for (let [key, value] of Object.entries(success.params)) {
              msg = msg.replace(key, value)
            }
          }
          this.openToastNotificationForMessage(msg, 'success')
        }
      }
      return doesHaveErrors
    }
  }
}