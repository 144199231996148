<template>
    <div class="row">
        <!-- Family -->
        <div class="col-12">
            <div class="radiogroupdiv">
                <!-- Award Family List -->
                <div class="form-check border-bottom" v-for="(member, memberIndex) in members" :key="memberIndex">
                    <input  class="form-check-input" 
                            type="radio" 
                            name="exampleRadios" 
                            :id="`exampleRadios${memberIndex}`" 
                            :value="member.id"
                            :checked="(selected_family_member == member.id) ? true : false"
                            @change="sendUpdateEmit(member)"
                            >
                    <label class="form-check-label" :for="`exampleRadios${memberIndex}`">
                    {{member.Translations !== undefined ? member.Translations.en.name : member}}
                    </label>
                    <div class="award-img-container">
                        <template v-if="member.widgetType === 'icon' && member.iconClass !== ''">
                          <em :class="member.iconClass" class="fa-3x fa-timg fa-image fa-opacity-20 svg-icon" :style="`color:${member.iconColor}`"></em>
                        </template>
                        <template v-else>
                          <img class="award-img" :src="assetUrl + member.image" v-if="member.image !== ''" />
                          <img src="@/assets/images/icon1.png" class="award-img" v-else />
                        </template>
                    </div>
                    <award-accordion>
                      <template v-slot:content>
                        <p class="award-description">{{member.Translations.en.description ? member.Translations.en.description : "No Description Available"}}</p>
                      </template>
                    </award-accordion>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import eventEmit from '@/mixin/index.js'

import AwardAccordion from "@/components/AwardAccordion";

export default {
    data() {
      return {
        assetUrl: this.$store.getters.domain,
        clickedItem: ''
      };
    },
    mixins: [eventEmit],
    props: ['members', 'selected_family_member'],
    components: {
        AwardAccordion
    },
    methods:{
        /**
         * Emit For Change
         */
        sendUpdateEmit(member){
            this.$emit('change-family', {member: member})
        }
    },
    mounted () {
      this.sendResizeContent()
    }
}
</script>

<style>
    /*img.member-image { max-width: 125px; }*/

    
</style>